import { render, staticRenderFns } from "./BoletoAndPixConfirmation.vue?vue&type=template&id=e4e89b54&scoped=true&"
import script from "./BoletoAndPixConfirmation.vue?vue&type=script&lang=js&"
export * from "./BoletoAndPixConfirmation.vue?vue&type=script&lang=js&"
import style0 from "./BoletoAndPixConfirmation.vue?vue&type=style&index=0&id=e4e89b54&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4e89b54",
  null
  
)

/* custom blocks */
import block0 from "./BoletoAndPixConfirmation.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
