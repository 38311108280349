import { render, staticRenderFns } from "./LoanAppStatusDetailsCard.vue?vue&type=template&id=1b3398f0&scoped=true&"
import script from "./LoanAppStatusDetailsCard.vue?vue&type=script&lang=ts&"
export * from "./LoanAppStatusDetailsCard.vue?vue&type=script&lang=ts&"
import style0 from "./LoanAppStatusDetailsCard.vue?vue&type=style&index=0&id=1b3398f0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b3398f0",
  null
  
)

/* custom blocks */
import block0 from "./LoanAppStatusDetailsCard.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports