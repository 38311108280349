




























import { defineComponent } from "@vue/composition-api"
import store, { usePaymentPlansStore } from "@/store"
import analytics from "@/lib/analytics"

export default defineComponent({
  name: "PaymentPlanPaymentConfirmation",
  setup(_, { root }) {
    const paymentMethods = store.getters["paymentPlans/availablePaymentMethods"]
    const { confirmed, confirmOffer, fetchBarcodeByPaymentMethod, loading, currentPaymentMethod, selectedOffer } = usePaymentPlansStore()
    const routeToConfirmation = () => {
      root.$router.push({
        name: "BoletoAndPixConfirmation"
      })
    }
    const onConfirm = async () => {
      if (confirmed.value) {
        routeToConfirmation()
        return
      }
      const confirmOfferResult = await confirmOffer()
      const fetchBarcodeByPaymentMethodResult = await fetchBarcodeByPaymentMethod()

      if (!fetchBarcodeByPaymentMethodResult) {
        root.$router.push("/loan-status")
      }

      if (confirmOfferResult) {
        routeToConfirmation()
      }
      // error display happens in store methods
    }

    const onBack = () => {
      root.$router.go(-1)
    }

    const paymentMethodImage = (paymentMethod: string) => {
      return require(`@/assets/images/svg/${paymentMethod.toLowerCase()}_btn.svg`)
    }

    const scrollToConfirm = () => {
      root.$nextTick().then(() => {
        const element = document.getElementById("confirm-payment-option")
        const scrollParams = { block: "center", behavior: "smooth" } as ScrollIntoViewOptions
        element?.scrollIntoView(scrollParams)
      })
    }

    const setCurrentPaymentMethod = (method: string) => {
      currentPaymentMethod.value = method
      selectedOffer.value = store.getters["paymentPlans/matchingOfferByPaymentMethod"]
      scrollToConfirm()
    }

    return {
      selectedOffer,
      onBack,
      onConfirm,
      loading,
      paymentMethods,
      currentPaymentMethod,
      paymentMethodImage,
      setCurrentPaymentMethod
    }
  }
})
