<template>
  <div id="boleto-pix-confirmation">
    <span id="first-due-date">{{ $t("firstDueDate", {date: new Date(selectedOffer.first_due_date).toLocaleDateString('pt-BR') }) }}</span>
    <div id="payment-plan-cta-container">
      <div id="qrcode-container" @click="onCopy('Pix')">
        <qrcode-vue class="qrcode" :value="pixBarcode" :size="250" level="L" />
      </div>
      <div id="payment-plan-cta-buttons-container">
        <v-btn id="pix-copy" class="cta-button" color="#048005" @click="onCopy('Pix')">
          <transition :duration="5000" name="fade">
            <span v-if="showBarcodeToolTip" id="copied-tooltip" v-html="$t('copied')" />
          </transition>
          {{ $t('pixCopy') }}
        </v-btn>
        <v-btn id="boleto-copy" class="cta-button" @click="onCopy('Boleto')">
          {{ $t('boletoCopy') }}
        </v-btn>
        <v-btn id="pdf" :loading="loading" class="cta-button" @click="onPdf">
          {{ $t('pdf') }}
        </v-btn>
      </div>
    </div>
    <p v-if="selectedOffer.number_of_installments > 1" v-html="$t('multipleInstallmentsMessage')" />
    <span id="back" @click="onBack" v-html="$t('goBack')" />
  </div>
</template>
<script>
import { defineComponent, ref } from "@vue/composition-api"
import { useAccountStore, usePaymentPlansStore } from "@/store"
import ConfirmationHeadline from "./ConfirmationHeadline.vue"
import QrcodeVue from "qrcode.vue"

export default defineComponent({
  components: { ConfirmationHeadline, QrcodeVue },
  name: "BoletoAndPixConfirmation",
  setup(_, { emit, root  }) {
    const { confirmed, selectedOffer, pixBarcode, boletoBarcode, getBoletoPdf, boletoPdf, loading } = usePaymentPlansStore()
    const { accountID } = useAccountStore()
    const showBarcodeToolTip = ref(false)

    const onCopy = (method) => {
      showBarcodeToolTip.value = true
      const code = (method === "Pix" ? pixBarcode.value : boletoBarcode.value)
      emit("copy-clicked", code)
      setTimeout(() => {
        showBarcodeToolTip.value = false
      }, 3000)
    }
    const onPdf = async () => {
      if (!await getBoletoPdf(accountID.value)) {
        // error handled in store
        return
      }
      emit("pdf-clicked", boletoPdf.value)
    }
    const onBack = () => {
      root.$router.push("/loan-status")
    }
    return {
      loading,
      confirmed,
      selectedOffer,
      onCopy,
      onPdf,
      onBack,
      pixBarcode,
      showBarcodeToolTip
    }
  }
})
</script>
<style lang="scss" scoped>
@import '~/src/scss/variables.scss';
#first-due-date {
  display: block;
  text-align: center;
}
.qrcode {
  width: 250px;
  display: block;
  margin: 0 auto;
  &:hover {
    cursor: pointer;
  }
}
#payment-plan-cta-container {
  padding: 2em 0;
}
#payment-plan-cta-buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1.25em auto;
  .cta-button {
    margin: 1em auto;
    background-color: $simplic-yellow;
    text-transform: capitalize;
    color: $simplic-copy;
    padding: 1.5em 1.5em;
    font-size: 0.9em;
    font-weight: bold;
    letter-spacing: 0;
    width: 100%;
    max-width: 300px;
  }
  #pix-copy {
    color: white;
  }
}
#back {
  text-decoration: underline;
  color: #185399;
  margin-top: 1em;
  &:hover {
    cursor: pointer;
  }
}
#copied-tooltip {
  position: absolute;
  top: -60px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.5em 1.5em;
  border-radius: 15px;
  font-size: 16px;
}
#back {
  display: block;
  text-align: center;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .45s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media(min-width: 375px) {
  #payment-plan-cta-buttons-container {
    .cta-button {
      font-size: 1.25em;
    }
  }
}
@media(min-width: 940px) {
  #payment-plan-cta-container {
    display: flex;
  }
  #payment-plan-cta-buttons-container {
    margin: 0;
    margin-left: 2em;
    .cta-button {
      margin: 0.5em;
    }
  }
}
</style>
<i18n>
{
  "pt": {
    "firstDueDate": "Data de Vencimento: {date}",
    "goBack": "&#8249; Voltar para Meu Empréstimo Atual",
    "pixCopy": "Copiar código PIX",
    "boletoCopy": "Copiar código Boleto",
    "pdf": "Gerar PIX/Boleto",
    "copied": "Copiado",
    "multipleInstallmentsMessage": "Estes dados se referem à sua primeira parcela. Os dados para pagamento das demais parcelas serão encaminhados por e-mail."
  }
}
</i18n>
