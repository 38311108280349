
















import { defineComponent, onMounted, Ref, ref } from "@vue/composition-api"
import { useAccountStore } from "@/store"
import PhoneNumberForm from "@/components/loan-processing/PhoneNumberForm.vue"
import CodeVerification from "@/components/loan-processing/CodeVerification.vue"

export default defineComponent({
  name: "ConfirmTelephone",
  components: { PhoneNumberForm, CodeVerification },
  setup(_, { root, emit }) {
    const showVerification: Ref<boolean> = ref(false)
    const { phoneNumber } = useAccountStore()
    const goToLP = () => {
      root.$router.push({
        name: "LoanProcessing",
        params: { skipActivities: "true" }
      })
    }
    const phoneNumberValidated = async (validated: boolean) => {
      if (validated) {
        goToLP()
      }
    }
    return {
      phoneNumber,
      phoneNumberValidated,
      showVerification
    }
  }
})
