































import { defineComponent, onMounted, reactive } from "@vue/composition-api"
import Cookies from "js-cookie"
import contact from "@/config/contact"
import analytics from "@/lib/analytics"
const whatsappExperiment = reactive({ value: "control" })
export default defineComponent({
  name: "Contact",
  setup() {
    onMounted(() => {
      const sessionId = Cookies.get("session_id")
      const whatsappExperimentEnabled = analytics.plugins.optimizely.isFeatureEnabled("whatsapp_footer_experiment")
      if (whatsappExperimentEnabled) {
        const experiment = analytics.plugins.optimizely.getVariation("whatsapp_footer", sessionId)
        if (experiment) {
          whatsappExperiment.value = experiment
        }
      }
    })
    return {
      contact,
      whatsappExperiment
    }
  }
})
